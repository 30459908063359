import React from 'react'
import './income-tax-calculator.m.css'

export const IncomeTax = () => {
  return (
    <div className="container">
      <h1 styleName='invisible_headline'>Income Tax Calculator</h1>
    <iframe
      style={{ border: '0 none', width: '100%', height: '1530px', overflow: 'hidden' }}
      src="https://blog.bankbazaar.com/tax-calculator/"
      title="Tax Calculator"
      allowFullScreen
      loading="lazy"
      styleName="iframe-container"
    ></iframe>
  </div>
  )
}
