import React from 'react';
import { object, string, objectOf, any } from 'prop-types';

import { DATE_FORMATS } from '../../../constants';
// import { getStoryHeaderData } from '../../../helpers/story-helpers';
import withStory from '../../with-story';
import { formatDate } from '../../../helpers/utils';

import './search-list.m.css';

import { BQLink } from '../../atoms/bqlink';
import CardImage from '../../atoms/cardimage';

const Searchlistitem = ({
  slug = '',
  headline = '',
  showFooter = true,
  imageData = {},
  story = {},
  redirectStorySlug
}) => {
  return (
    <article styleName="article">
      <div styleName="image-container">
        <BQLink href={redirectStorySlug || `/${slug}`}>
          <CardImage story={story} aspectRatio={[16, 9]} imageType="image16x9" />
        </BQLink>
      </div>
      <div styleName="content">
        <a href={redirectStorySlug || `/${slug}`}>
          <h2 styleName="headline">{story.headline}</h2>
        </a>
        {/* <div styleName="summary">{story['author-name']}</div> */}
        {showFooter && (
          <div styleName="content-footer">
            <time
              dateTime={formatDate(story['published-at'], DATE_FORMATS.DATE_TIME)}
              styleName="timestamp"
            >
              {formatDate(story['published-at'], DATE_FORMATS.DATE_TIME)}
            </time>
            {/* <MediaQuery maxWidth={1199}>
            <Share
              title={headline}
              url={slug}
              summary={story.summary}
              hashtags={(story.tags || []).map(t => t.name)}
              services={[
                'facebook',
                'twitter',
                'linkedin',
                'whatsapp',
                'telegram',
                'reddit',
                'copy'
              ]}
              shareStyle="blue"
            />
          </MediaQuery>
          <MediaQuery minWidth={1200}>
            <Share
              title={headline}
              url={slug}
              summary={story.summary}
              hashtags={(story.tags || []).map(t => t.name)}
              services={[
                'facebook',
                'twitter',
                'linkedin',
                'whatsapp',
                'telegram',
                'reddit',
                'copy'
              ]}
              servicesToShowByDefault={2}
              showToggleWhenExpanded={false}
            />
          </MediaQuery> */}
          </div>
        )}
      </div>
    </article>
  );
};

Searchlistitem.propTypes = {
  story: objectOf(any).isRequired,
  slug: string.isRequired,
  headline: string.isRequired,
  imageData: object.isRequired,
  redirectStorySlug: string
};

export default withStory(Searchlistitem);
