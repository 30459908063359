import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import StateOfTheEconomy from '../../collection-templates/state-of-the-economy';

const StateOfTheEconomyFullWidget = (props) => {
  const queryClient = new QueryClient;

  return (
    <QueryClientProvider client={queryClient}>
      <StateOfTheEconomy widget={true} target="_parent" {...props} />
    </QueryClientProvider>
  );
};

export { StateOfTheEconomyFullWidget };
