import React, { useState } from 'react';
import { BQLink } from '../../atoms/bqlink';
import CardImage from '../../atoms/cardimage';
import './story-portrait.m.css';

export const StoryPortraitItem = ({ story }) => {
  return (
    <div styleName="collection-wrapper">
      <div>
        <BQLink href={`${story.slug}`}>
          <CardImage story={story} imageType="image16x9" aspectRatio={[16, 9]} />
          <div styleName="name-wrapper">{story.headline} </div>
        </BQLink>
      </div>
    </div>
  );
};
