import React from 'react';
import { PATH } from '../../../constants';
import { BQLink } from '../../atoms/bqlink';
import SvgIcon from '../../atoms/svg-icon-handler';
import './not-found-page.m.css';

const NotFoundPage = (props) => {
  // const [stories, setStories] = useState([]);
  // const [heading, setHeading] = useState('Top Stories');
  // useEffect(() => {
  //   wretch(
  //     `${props.config['sketches-host']}/api/v1/collections/above-the-fold?item-type=story&limit=6`
  //   )
  //     .get()
  //     .json((response) => {
  //       const data = get(response, ['items']);
  //       setStories(data);
  //     });
  // }, []);

  return (
    <div className="container not-found">
      <div styleName="not-found-wrapper">
        <div className="container">
          <h1 styleName="not-found-error">404</h1>
          <div styleName="error-message">
            <span>Oops, Page not Found</span>
            <div styleName="error-box">
              Unfortunately, this page does not exist
              <span styleName="divider">or</span>
            </div>
            <a styleName="return-link" href={`${PATH.HOME}`}>
              <span>होमपेज पर जाएं</span>
              <SvgIcon type='ic_chevron_right_white_24px' height='10px' width='10px' />
            </a>
            <div styleName="stories-container-note">या अपना URL जांचें</div>
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div styleName="stories-parent">
          <div styleName="stories-header">
            <div styleName="stories-heading">
              <TitleWithDivider>
                <ListHeaderTitle title={heading} />
              </TitleWithDivider>
            </div>
          </div>
          <div styleName="stories-container">
            {stories.map((story, index) => (
              <React.Fragment key={index}></React.Fragment>
            ))}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export { NotFoundPage };
