import React from 'react';
import PropTypes from 'prop-types';
import DfpAd from './../../atoms/dfp-ad';
import './story-list.m.css';
import StoryListItem from './story-list-item';

const adsIndices = [2, 6, 10, 14, 18];

const StoryList = ({ collection = {}, stories = [], redirectFirstStorySlug, customStyle }) => {
  let storyList = collection?.items?.map(({ story }) => story);
  if (!storyList || !storyList.length) {
    storyList = stories;
  }

  return (
    <section className="story-list" styleName={customStyle}>
      {storyList.map(
        (story, index) =>
          story && (
            <React.Fragment key={story.id}>
              <StoryListItem
                showFooter={false}
                story={story}
                redirectStorySlug={
                  index === 0 && redirectFirstStorySlug ? redirectFirstStorySlug : null
                }
              />
              {/* {adsIndices.includes(index + 1) && (
                <div className="content-center">
                  <DfpAd adtype="section-2" slotId={`section-2_${index}`} />
                </div>
              )} */}
            </React.Fragment>
          )
      )}
    </section>
  );
};

StoryList.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object),
  redirectFirstStorySlug: PropTypes.string,
  collection: PropTypes.arrayOf(PropTypes.object)
};

export default StoryList;
