import React from 'react';
import { string } from 'prop-types';

import './app-logo.m.css';

import SvgIconHandler from '../../atoms/svg-icon-handler';
import { BQLink } from '../../atoms/bqlink';

const AppLogo = ({ width = '175px' }) => {
  return (
    <BQLink href="/?src=home" aria-label="app-logo" styleName="logo-link">
      <SvgIconHandler
        type="NDTV_hindi_logo"
        styleName="publisher-logo"
        isSvg={false}
        width={width}
      />
    </BQLink>
  );
};

AppLogo.propTypes = {
  width: string
};
export { AppLogo };
