import React from 'react'
import { BQLink } from '../../atoms/bqlink'

export const Calculators = () => {
  return (
    <div className='container'><BQLink href='/calulator'>
      Calculators
      </BQLink></div>
  )
}
