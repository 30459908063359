import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchList from '../molecules/search-list/search-list';
import GenericTitle from '../molecules/titles/generic-title';
import RightSidebar from '../molecules/right-side-bar';
import Noresult from '../atoms/no-result/no-result';
import { concat } from 'lodash';
import SvgIcon from '../atoms/svg-icon-handler';
import Button from '../atoms/Button';
import { sortStoriesByLastPublishedAt } from '../../helpers/utils';

import './search.m.css';

const SearchPage = (props) => {
  const [offset, setOffset] = useState(0);
  const [stories, setStories] = useState([]);
  const collection = {
    items: stories.slice(0, offset)
  };

  const getMoreStories = async () => {
    fetch(
      `/api/v1/search?q=${props.data.query}&fields=headline,hero-image-s3-key,id,author-name,slug&limit=100`
    )
      .then((res) => res.json())
      .then((res) => {
        const updatedStories = concat(
          stories,
          res.results.stories.map((item) => {
            return {
              story: item
            };
          })
        );
        setStories(sortStoriesByLastPublishedAt(updatedStories));
      });
    setOffset((offset) => offset + 10);
  };

  useEffect(() => {
    getMoreStories();
  }, []);

  return (
    <div className="container" styleName="searchResultsPage">
      <div styleName="top-bg-section" />
      <div styleName="wrapper" className="row-section sidebar">
        <div styleName="list-with-sidebar-wrapper">
          <div styleName="title-wrapper">
            <GenericTitle
              className="collection-name"
              styleClass="light-collection-name"
              title={`Search Results - (${
                props.data.query.length > 15
                  ? props.data.query.substring(0, 15) + '...'
                  : props.data.query
              })`}
              logo={false}
            />
          </div>

          {collection.items.length ? (
            <>
              <SearchList stories={collection} customStyle={'sidebar-stories'} />
              {offset > stories?.length ? null : (
                <Button
                  label="और देखें"
                  rounded="full"
                  variant="outline"
                  className="button_purple_border"
                  icon="right"
                  onClick={getMoreStories}
                >
                  <div styleName="expand-icon">
                    <SvgIcon type="ic_expand_more_24px" width="16px" height="16px" />
                  </div>
                </Button>
              )}
            </>
          ) : (
            <Noresult query={props.data.query} />
          )}
        </div>
        <RightSidebar collection={collection} stories={collection} />
      </div>
    </div>
  );
};

SearchPage.propTypes = {
  data: PropTypes.object
};

export { SearchPage };
