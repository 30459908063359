import React, { useState } from 'react';
import { object, shape } from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { getLoadMoreStories } from '../../utils';
import SvgIcon from '../../atoms/svg-icon-handler';

import { DfpComponent } from '../../ads/dfp-component';

import './author.m.css';
import CardImage from '../../atoms/cardimage';
import { StoryPortraitItem } from '../../molecules/story-portrait/story-portrait';

export const AuthorPage = (props) => {
  const adConfig = useSelector((state) =>
    get(state, ['qt', 'config', 'ads-config', 'slots', 'listing_page_ads'], {})
  );
  const authorCollection = get(props, ['data', 'authorCollection'], {});
  const [storiesToRender, setStoriesToRender] = useState(6);
  const [authorPageStories, setStories] = useState(authorCollection.items);

  const authorCollectionStories = {
    items: authorPageStories.slice()
  };

  const authorIntrCardConfig = {
    enableBio: true,
    enableSocialLinks: true
  };

  // const StoryPortraitItem = ({ story }) => {
  //   return (
  //     <div styleName="collection-wrapper">
  //       <div>
  //         <CardImage story={story} imageType="image16x9" aspectRatio={[16, 9]} />
  //         <div styleName="name-wrapper">{story.headline} </div>
  //       </div>
  //     </div>
  //   );
  // };

  const getMoreStories = async (offset, limit) => {
    await getLoadMoreStories({
      offset: offset,
      limit: 6,
      authorId: props.data.author.id,
      slug: props.data.query,
      setStories: setStories,
      storiesToRender: storiesToRender,
      setStoriesToRender: setStoriesToRender,
      stories: authorPageStories
    });
  };

  return (
    <div className="container" styleName="wrapper">
      {/* <AuthorIntroductionCard data={props.data.author} config={authorIntrCardConfig} /> */}
      <div styleName="author-container">
        <h3 styleName="author-name">
          <SvgIcon width={50} height={50} type="feather_purple_whitebg" alt="User icon" />
          {props.data.author.name}
        </h3>
        <div styleName="icon-handler">
          <SvgIcon width={150} height={150} type="user" alt="User icon" />
        </div>
      </div>
      {authorCollectionStories.items.length > 0 ? (
        <div styleName="story-collection-wrapper">
          {authorCollectionStories.items.map(({ story }, i) => (
            <StoryPortraitItem key={i + 1} story={story} />
          ))}
        </div>
      ) : (
        <h1 styleName="text-info">No stories found!</h1>
      )}
      {/* <DfpComponent
        adStyleName="ad-slot-size-300x250"
        id="author-page-ad"
        path={adConfig.ad_unit}
        size={adConfig.sizes}
        viewPortSizeMapping={adConfig.view_port_size_mapping}
      /> */}
    </div>
  );
};

AuthorPage.propTypes = {
  data: shape({
    author: object,
    authorCollection: object
  })
};
