import React from 'react';
import './about-us.m.css';
import GenericTitle from '../../molecules/titles/generic-title';

const AboutUsPage = () => {
  return (
    <article className="static-page" itemscope itemtype="http://schema.org/Article">
      <span>
        <div styleName="top-bg-section">
          <div className="container" styleName="title-wrapper">
            <GenericTitle
              styleClass="light-collection-name"
              title={'ABOUT US'}
              iconCode={'info-Icon-whiteBg'}
              logo={false}
              width="35px"
            />
          </div>
        </div>
      </span>
      <div className="container" styleName="about-us-container">
        <div>
          <img src="/images/aboutusmobile.png" className="mobile-only" />
          <img src="/images/aboutusdesktop.png" className="desktop-only" />
        </div>
        <div styleName="static-content">
          <div className="static-text">
            <p>
              NDTV Profit हिंदी, भारत के सबसे विश्वसनीय डिजिटल, मल्टीमीडिया बिजनेस न्यूज प्लेटफॉर्म
              NDTV Profit का हिस्सा है. NDTV Profit का स्वामित्व अदाणी ग्रुप कंपनी, AMG मीडिया
              नेटवर्क्स लिमिटेड के पास है.
            </p>
          </div>
          <div className="static-text">
            <p>
              अब NDTV Profit हिंदी, उसी भरोसे और नए तेवर के साथ बिजनेस, फाइनेंस और कंज्यूमर से जुड़ी
              हर खबर को सरल भाषा में आप तक पहुंचा रहा है.
            </p>
          </div>
          <div>
            <p>
              17 जनवरी, 2005 में लॉन्च हुआ NDTV Profit, 24*7 बिजनेस न्यूज चैनल है जो बिजनेस की
              दुनिया में स्वतंत्र, विश्लेषणात्मक और धारदार कवरेज के लिए जाना जाता है. स्टॉक मार्केट
              एक्शन के अलावा, इकोनॉमी और कॉरपोरेट दुनिया की ब्रेकिंग न्यूज और इन-डेप्थ एनालिसिस के
              लिए भी NDTV Profit एक भरोसेमंद न्यूज प्लेटफॉर्म है.
            </p>
          </div>
          <div className="static-text">
            <div className="reach-us">
              <h3>संपर्क करें </h3>
              <address className="address">
                <span>
                  संपादकीय प्रतिक्रिया:{' '}
                  <a
                    className="static-link"
                    href="mailto:hindi.profiteditor@ndtv.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    hindi.profiteditor@ndtv.com
                  </a>
                </span>
                <span>
                  फीडबैक:{' '}
                  <a
                    className="static-link"
                    href="mailto:hindi.hindi.profitsupport@ndtv.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    hindi.profitsupport@ndtv.com
                  </a>
                </span>
                <span>
                  सेल्स टीम:{' '}
                  <a
                    className="static-link"
                    href="mailto:hindi.profitsales@ndtv.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    hindi.profitsales@ndtv.com
                  </a>
                </span>
                <span>
                  जॉब:{' '}
                  <a
                    className="static-link"
                    href="mailto:hindi.profitjobs@ndtv.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    hindi.profitjobs@ndtv.com
                  </a>
                </span>
              </address>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export { AboutUsPage };
