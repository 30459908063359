import React from 'react';
import './no-result.m.css';

const Noresult = ({ query, tags }) => {
  return (
    <div styleName="noresult">
      <div styleName="image-heading">
        <img
          src="https://images.assettype.com/bloombergquint/2022-08/ee62089d-44b3-4490-bf9c-ce5559c2689b/noresult.png"
          alt="no results"
        />
        <p>
          Oops ! No match found related to <h4>" {query && query} "</h4>
        </p>
      </div>
      {/* <div styleName="tags">
        <h5>But you may explore other topics</h5>
        <div styleName="taglist">
          {tags &&
            tags.map((tag) => (
              <div
                onClick={() => (window.location.href = `${tag.path}?src=search-page`)}
                key={tag.path}
              >
                {tag.name}
              </div>
            ))}
        </div>
      </div> */}
    </div>
  );
};

export default Noresult;
